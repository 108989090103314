<template>
  <div class="promotion">
    <DialogVersion3 :visible.sync="visible" visibleFooter hideChat>
      <div class="top">
        <p class="title">{{ $t('promotion.selectDesp') }}</p>
      </div>

      <el-form label-position="top" :model="Form" ref="Form" :rules="FormRules">
        <el-form-item :label="$t('common.field.accNum')" prop="formAccount">
          <el-select v-model="Form.formAccount" data-testid="formAccount">
            <el-option
              v-for="item in formAccountOptions"
              :key="item.id"
              :value="JSON.stringify(item)"
              :label="item.mt4_account"
              :data-testid="item.mt4_account"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="form-box">
        <div class="btn-wrapper">
          <el-button
            class="btn-red"
            @click="applyAction"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="applyAction"
          >
            {{ $t('common.button.submit') }}
          </el-button>
        </div>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiApplyNoBonusCampaign, apiQueryMetaTraderAccountDetails } from '@/resource';

export default {
  props: {
    chooseAccountVisible: Boolean
  },
  components: { DialogVersion3 },
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      visible: false,
      Form: {
        formAccount: ''
      },
      formAccountOptions: [],
      selectedAccount: {}
    };
  },
  mounted() {},
  watch: {
    chooseAccountVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      if (bool) {
        this.fetchTradingAccount();
      }
      this.disableChooseAccountVisible(bool);
    },
    'Form.formAccount'(value) {
      const jsonParse = JSON.parse(value);
      this.selectedAccount = jsonParse;
    },
    formAccountOptions(options) {
      // 預設第一筆
      if (options.length > 0) {
        this.Form.formAccount = JSON.stringify(options[0]);
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    FormRules() {
      return {
        formAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ]
      };
    }
  },
  methods: {
    disableChooseAccountVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:chooseAccountVisible', bool);
      });
    },
    fetchTradingAccount() {
      apiQueryMetaTraderAccountDetails({
        user_id: this.uuid
      }).then(resp => {
        this.formAccountOptions = resp.data.filter(
          option => option.status === 1 && [1, 6, 13, 15].includes(option.mt4_account_type)
        );
      });
    },
    applyAction() {
      let mt4_account = this.selectedAccount.mt4_account;
      let mt4_datasource_id = this.selectedAccount.mt4_datasource_id;
      let currency = this.selectedAccount.currency;
      if (currency) {
        this.$refs['Form'].validate(valid => {
          if (valid) {
            apiApplyNoBonusCampaign({
              mt4_account,
              mt4_datasource_id,
              currency
            })
              .then(resp => {
                if (resp.data.code === 0 && resp.data.data) {
                  this.$message({
                    message: this.$t('promotion.successful'),
                    type: 'success'
                  });
                  this.$emit('update:isApplySucceed', true);
                  this.disableChooseAccountVisible(false);
                  this.$router.go();
                } else {
                  this.failReminder();
                }
              })
              .catch(err => {
                this.failReminder();
              });

            console.log(this.selectedAccount);
          } else return false;
        });
      }
    },
    failReminder() {
      this.$message({
        message: this.$t('promotion.failed'),
        type: 'error'
      });
      this.disableChooseAccountVisible(false);
      this.$router.push('/home');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/promotion.scss';
</style>
