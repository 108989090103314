<template>
  <div id="promotion">
    <div class="banner-container">
      <img alt class="banner-image" :src="require(`@/assets/images/banner/no_deposit_bonus/${imgLang}.png`)" />
    </div>
    <div class="content-box">
      <div class="inner">
        <h3>{{ $t('menu.noDeposit') }}</h3>
        <el-row type="flex" justify="center">
          <el-col :md="12" :sm="14">
            <div class="desc-text">{{ $t('promotion.descp1') }}</div></el-col
          >
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :md="2" :sm="3" :xs="6"> <img src="@/assets/images/promotion/dash.png" class="image-dash"/></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :md="8"> <div class="desc-text" v-html="$t('promotion.descp2')"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :md="15" :sm="18"
            ><div>
              <el-card :body-style="{ padding: '0px' }">
                <img :src="require(`@/assets/images/banner/no_deposit_bonus/${imgLang}.png`)" class="image" />
                <div class="bottom-wrapper ">
                  <div class="red_text" v-if="!isApplySucceed">{{ clickText }}</div>
                </div>
                <div class="button-wrapper" v-if="!isApplySucceed">
                  <el-button class="btn-red" @click="showTnc" data-testid="apply">{{
                    $t('promotion.start')
                  }}</el-button>
                  <div class="image-Wrapper">
                    <img src="@/assets/images/promotion/click.png" />
                  </div>
                </div>
              </el-card></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <AgreeTnc
      :agreeTncVisible.sync="agreeTncVisible"
      @isAgreeTnc="isAgreeTncHandler"
      :tncContent="`promotion.tnc`"
    ></AgreeTnc>
    <ChooseAccount
      :chooseAccountVisible.sync="chooseAccountVisible"
      :isApplySucceed.sync="isApplySucceed"
    ></ChooseAccount>
  </div>
</template>

<script>
import AgreeTnc from '@/components/promotion/common/AgreeTnc';
import ChooseAccount from '@/components/promotion/common/ChooseAccount';

export default {
  name: 'promotion',
  components: {
    AgreeTnc,
    ChooseAccount
  },
  data() {
    return {
      agreeTncVisible: false,
      isAgreeTnc: false,
      chooseAccountVisible: false,
      isApplySucceed: false
    };
  },
  methods: {
    showTnc() {
      this.agreeTncVisible = true;
    },
    isAgreeTncHandler(val) {
      if (val) {
        this.chooseAccountVisible = true;
      }
    }
  },
  computed: {
    clickText() {
      return '>>> ' + this.$t('promotion.clickToGet') + ' <<<';
    },
    imgLang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    chooseAccountVisible(bool) {
      if (!bool) {
        this.isAgreeTnc = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion.scss';
</style>
